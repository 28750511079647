<template>
  <v-container>
    <v-row class="text-left">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain

        />
      </v-col>

<!--      <v-col class="mb-4" cols="12">-->
<!--        <h2 class="display-1 mb-3 blue&#45;&#45;text">-->
<!--          Herzlich Willkommen zu Alampasis Haustechnik & Hausmeisterservices-->
<!--        </h2>-->
<!--      </v-col>-->

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Unsere Services
        </h2>
        <p>Sanitär - Heizung - Klima</p>
        <p>Lüftungsanlagen - Solaranlagen - Wärmepumpen</p>
        <p>Bad- & Wohnungssanierungen</p>
        <p>Fliesen - Laminat - Tockenbau - Fenter Türen</p>

        <h2 class="headline font-weight-bold mb-3">
          Anschrift
        </h2>

        <address>
          Alampasis Haustechnik & Hausmeisterservices <br>
          Industirestrße 5c, 68169 <br>
          Telefon: 0621 32696747<br>
          Email: <a href="mailto:Alampasis@aol.de"> Alampasis@aol.de</a><br>
        </address>
        <v-row justify="center">
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({

    }),
  }
</script>
